<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import ApiClass from "../../api/api";

export default {
  page: {
    title: "Edit Crypto",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
  },

  data() {
    return {
      title: "Edit Crypto",
      currency: "",
      items: [
        {
          text: "Crypto",
          href: "#",
        },
        {
          text: "Edit Crypto",
          active: true,
        },
      ],
      submitted: false,
      pair_with_options: ["USDT", "BTC", "TRX", "ETH"],
      status_options: [
        { key: "1", value: "Active" },
        { key: "0", value: "Not Active" },
      ],
      decimalLength: "8",
      image: "",
      loading: false,
      form: {
        buy: "",
        buy_min: "",
        buy_max: "",
        buy_desc: "",
        sell_desc: "",
        buy_commission: "",
        sell: "",
        sell_min: "",
        sell_max: "",
        sell_commission: "",
        deposit: "",
        deposit_min: "",
        deposit_max: "",
        deposit_commission: "",
        withdraw: "",
        withdraw_min: "",
        withdraw_max: "",
        withdraw_commission: "",
        buy_commission_type: "percentage",
        sell_commission_type: "percentage",
        deposit_commission_type: "percentage",
        withdraw_commission_type: "percentage",
        buy_min_desc: "",
        buy_max_desc: "",
        sell_min_desc: "",
        sell_max_desc: "",
      },
    };
  },
  async mounted() {
    var result = await ApiClass.getRequest(
      "admin/crypto/get/" + this.$route.params.id,
      true
    );
    console.log(result.data.data);
    if (result.data.status_code == 1 && result.data.data != "undefined") {
      this.currency = result.data.data.currency;
      this.form.buy =
        result.data.data.buy == 1
          ? this.status_options[0]
          : this.status_options[1];
      this.form.buy_min = result.data.data.buy_min;
      this.form.buy_max = result.data.data.buy_max;
      this.form.buy_commission = result.data.data.buy_commission;

      this.form.buy_desc = result.data.data.buy_desc;
      this.form.buy_min_desc = result.data.data.buy_min_desc;
      this.form.buy_max_desc = result.data.data.buy_max_desc;

      this.form.sell_desc = result.data.data.sell_desc;
      this.form.sell_min_desc = result.data.data.sell_min_desc;
      this.form.sell_max_desc = result.data.data.sell_max_desc;

      this.form.sell =
        result.data.data.sell == 1
          ? this.status_options[0]
          : this.status_options[1];
      this.form.sell_min = result.data.data.sell_min;
      this.form.sell_max = result.data.data.sell_max;
      this.form.sell_commission = result.data.data.sell_commission;
      this.form.deposit =
        result.data.data.deposit == 1
          ? this.status_options[0]
          : this.status_options[1];
      this.form.deposit_min = result.data.data.deposit_min;
      this.form.deposit_max = result.data.data.deposit_max;
      this.form.deposit_commission = result.data.data.deposit_commission;
      this.form.withdraw =
        result.data.data.withdraw == 1
          ? this.status_options[0]
          : this.status_options[1];
      this.form.withdraw_min = result.data.data.withdraw_min;
      this.form.withdraw_max = result.data.data.withdraw_max;
      this.form.withdraw_commission = result.data.data.withdraw_commission;
    } else {
      this.$router.push({ name: "UpdateCrypto" });
    }
  },
  methods: {
    async save() {
      this.submitted = true;
      if (this.$v.$invalid) {
        return;
      }
      this.submitted = false;
      this.loading = true;
      let formData = new FormData();

      formData.append("buy", this.form.buy.key);
      formData.append("buy_min", this.form.buy_min);
      formData.append("buy_max", this.form.buy_max);
      formData.append("buy_commission", this.form.buy_commission);
      formData.append("buy_commission_type", this.form.buy_commission_type);

      formData.append("sell", this.form.sell.key);
      formData.append("sell_min", this.form.sell_min);
      formData.append("sell_max", this.form.sell_max);
      formData.append("sell_commission", this.form.sell_commission);
      formData.append("sell_commission_type", this.form.sell_commission_type);
      formData.append("buy_desc", this.form.buy_desc);
      formData.append("sell_desc", this.form.sell_desc);
      formData.append("buy_min_desc", this.form.buy_min_desc);
      formData.append("buy_max_desc", this.form.buy_max_desc);
      formData.append("sell_max_desc", this.form.sell_max_desc);
      formData.append("sell_min_desc", this.form.sell_min_desc);

      // formData.append("deposit", this.form.deposit.key);
      // formData.append("deposit_min", this.form.deposit_min);
      // formData.append("deposit_max", this.form.deposit_max);
      // formData.append("deposit_commission", this.form.deposit_commission);
      // formData.append(
      //   "deposit_commission_type",
      //   this.form.deposit_commission_type
      // );

      // formData.append("withdraw", this.form.withdraw.key);
      // formData.append("withdraw_min", this.form.withdraw_min);
      // formData.append("withdraw_max", this.form.withdraw_max);
      // formData.append("withdraw_commission", this.form.withdraw_commission);
      // formData.append(
      //   "withdraw_commission_type",
      //   this.form.withdraw_commission_type
      // );

      var result = await ApiClass.updateFormRequest(
        "admin/crypto/update/" + this.$route.params.id,
        true,
        formData
      );

      if (result.data.status_code == 1) {
        this.loading = false;
        Swal.fire({
          position: "center",
          icon: "success",
          title: result.data.message,
          showConfirmButton: true,
          timer: 5000,
        });
      } else {
        this.loading = false;
        Swal.fire({
          position: "center",
          icon: "error",
          title: result.data.message,
          showConfirmButton: true,
          timer: 5000,
        });
      }
    },
    keyChange(event, decimal = null) {
      if (decimal == 0 && event.charCode == 46) {
        return event.preventDefault();
      }

      let res =
        event.charCode != 8 &&
        ((event.charCode >= 48 && event.charCode <= 57) ||
          (event.charCode == 46 && event.target.value.indexOf(".") == -1));

      if (decimal != null && res && event.target.value.indexOf(".") != -1) {
        res = event.target.value.split(".")[1].length < decimal;
      }

      return res ? res : event.preventDefault();
    },
  },
  validations: {
    form: {
      buy: { required },
      buy_min: { required },
      buy_max: { required },
      buy_commission: {
        required,
        // maxValue: maxValue("100"),
      },
      sell: { required },
      sell_min: { required },
      sell_max: { required },
      sell_commission: {
        required,
        // maxValue: maxValue("100"),
      },
      sell_desc: { required },
      buy_desc: { required },
      buy_min_desc: { required },
      buy_max_desc: { required },
      sell_min_desc: { required },
      sell_max_desc: { required },
      // deposit: { required },
      // deposit_min: { required },
      // deposit_max: { required },
      // deposit_commission: {
      //   required,
      //   // maxValue: maxValue("100"),
      // },
      // withdraw: { required },
      // withdraw_min: { required },
      // withdraw_max: { required },
      // withdraw_commission: {
      //   required,
      //   // maxValue: maxValue("100"),
      // },
    },
  },
};
``;
</script>

<template>
  <div>
    <Layout>
      <PageHeader :title="title" :items="items" />
      <form @submit.prevent="save">
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 mx-auto">
            <div class="add_coin_form_box mb-3">
              <div class="row middle-row align-items-center">
                <div class="col-xl-6 col-lg-6 col-md-6 col-12 mb-3">
                  <div
                    class="form-group"
                    :class="{ 'form-group--error': $v.form.buy.$error }"
                  >
                    <label for="selectbuy" class="form-label">Buy</label>
                    <multiselect
                      v-model.trim="$v.form.buy.$model"
                      track-by="key"
                      label="value"
                      :options="status_options"
                    ></multiselect>
                    <div
                      class="error"
                      v-if="submitted && !$v.form.buy.required"
                    >
                      Buy Status is required
                    </div>
                  </div>
                  <!--form-group-->
                </div>
                <!--col-6 Buy-->

                <div class="col-xl-3 col-lg-6 col-md-6 col-12 mb-3">
                  <div
                    class="form-group"
                    :class="{ 'form-group--error': $v.form.buy_min.$error }"
                  >
                    <label for="selectbuy" class="form-label">Min Limit</label>

                    <div class="input-group mb-3">
                      <input
                        type="text"
                        class="form-control"
                        id="buymin"
                        placeholder="Min"
                        @keypress="keyChange($event, decimalLength)"
                        onpaste="return false;"
                        v-model="$v.form.buy_min.$model"
                        aria-describedby="buymin"
                      />
                      <span class="input-group-text" id="buymin">$</span>
                    </div>

                    <div
                      class="error"
                      v-if="submitted && !$v.form.buy_min.required"
                    >
                      Min Limit is required
                    </div>
                  </div>
                  <!--form-group-->
                </div>
                <!--col-6 Min-->

                <div class="col-xl-3 col-lg-6 col-md-6 col-12 mb-3">
                  <div
                    class="form-group"
                    :class="{ 'form-group--error': $v.form.buy_max.$error }"
                  >
                    <label for="selectbuy" class="form-label">Max Limit</label>

                    <div class="input-group mb-3">
                      <input
                        type="text"
                        class="form-control"
                        id="buymax"
                        placeholder="Max"
                        @keypress="keyChange($event, decimalLength)"
                        onpaste="return false;"
                        v-model="$v.form.buy_max.$model"
                        aria-describedby="buymax"
                      />
                      <span class="input-group-text" id="buymax">$</span>
                    </div>

                    <div
                      class="error"
                      v-if="submitted && !$v.form.buy_max.required"
                    >
                      Max Limit is required
                    </div>
                  </div>
                </div>
                <!--col-6 Max-->

                <div class="col-xl-6 col-lg-6 col-md-6 col-12 mb-3">
                  <div
                    class="form-group"
                    :class="{
                      'form-group--error': $v.form.buy_commission.$error,
                    }"
                  >
                    <label for="selectbuy" class="form-label"
                      >Buy commission</label
                    >

                    <div class="input-group mb-3">
                      <select
                        class="form-select shadow-none"
                        v-model="form.buy_commission_type"
                      >
                        <option value="percentage">Percentage</option>
                        <option value="flat">Flat</option>
                      </select>
                      <input
                        type="text"
                        class="form-control"
                        id="buycomm"
                        placeholder="Commission"
                        @keypress="keyChange($event, 2)"
                        onpaste="return false;"
                        v-model="$v.form.buy_commission.$model"
                        aria-describedby="buycomm"
                      />
                      <span
                        class="input-group-text"
                        id="buycomm"
                        v-if="form.buy_commission_type == 'percentage'"
                        >%</span
                      >
                      <span class="input-group-text" id="buycomm" v-else>{{
                        currency
                      }}</span>
                    </div>

                    <div
                      class="error"
                      v-if="submitted && !$v.form.buy_commission.required"
                    >
                      Buy commission is required
                    </div>
                    <div
                      class="error"
                      v-if="
                        form.buy_commission > 99 &&
                        form.buy_commission_type == 'percentage'
                          ? (customError = true)
                          : (customError = false)
                      "
                    >
                      Maximum percentage is 99.
                    </div>
                    <div
                      class="error"
                      v-if="
                        form.buy_min != '' &&
                        parseFloat(form.buy_commission) >
                          parseFloat(form.buy_min) &&
                        form.buy_commission_type == 'flat'
                          ? (customError = true)
                          : (customError = false)
                      "
                    >
                      Buy value should be less than Min limit.
                    </div>
                  </div>
                  <!--form-group-->
                </div>
                <div class="col-xl-3 col-lg-6 col-md-6 col-12 mb-3">
                  <div
                    class="form-group"
                    :class="{
                      'form-group--error': $v.form.buy_min_desc.$error,
                    }"
                  >
                    <label for="selectbuy" class="form-label"
                      >Buy Min Description</label
                    >

                    <div class="input-group mb-3">
                      <textarea
                        class="form-control"
                        name="buy_desc"
                        id=""
                        cols="50"
                        rows="3"
                        v-model.trim="$v.form.buy_min_desc.$model"
                      ></textarea>
                    </div>

                    <div
                      class="error"
                      v-if="submitted && !$v.form.buy_min_desc.required"
                    >
                      Buy Min Description is required.
                    </div>
                  </div>
                  <!--form-group-->
                </div>
                <!--col-6 Min-->

                <div class="col-xl-3 col-lg-6 col-md-6 col-12 mb-3">
                  <div
                    class="form-group"
                    :class="{
                      'form-group--error': $v.form.buy_max_desc.$error,
                    }"
                  >
                    <label for="selectbuy" class="form-label"
                      >Buy Max Description</label
                    >

                    <div class="input-group mb-3">
                      <textarea
                        class="form-control"
                        name="buy_desc"
                        id=""
                        cols="50"
                        rows="3"
                        v-model.trim="$v.form.buy_max_desc.$model"
                      ></textarea>
                    </div>

                    <div
                      class="error"
                      v-if="submitted && !$v.form.buy_max_desc.required"
                    >
                      Buy Max Description is required
                    </div>
                  </div>
                </div>

                <div class="col-xl-6 col-lg-6 col-md-6 col-12 mb-3">
                  <div
                    class="form-group"
                    :class="{
                      'form-group--error': $v.form.buy_desc.$error,
                    }"
                  >
                    <label for="selectbuy" class="form-label"
                      >Buy description</label
                    >

                    <div class="input-group mb-3">
                      <textarea
                        class="form-control"
                        name="buy_desc"
                        id=""
                        cols="100"
                        rows="3"
                        v-model.trim="$v.form.buy_desc.$model"
                      ></textarea>
                    </div>

                    <div
                      class="error"
                      v-if="submitted && !$v.form.buy_desc.required"
                    >
                      Buy description is required
                    </div>
                  </div>
                  <!--form-group-->
                </div>
              </div>
              <!--row form-row-->
            </div>
            <!--buy_box-->

            <div class="add_coin_form_box mb-3">
              <div class="row bottom-row align-items-center">
                <div class="col-xl-6 col-lg-6 col-md-6 col-12 mb-3">
                  <div
                    class="form-group"
                    :class="{ 'form-group--error': $v.form.sell.$error }"
                  >
                    <label for="selectbuy" class="form-label">Sell</label>
                    <multiselect
                      v-model.trim="$v.form.sell.$model"
                      track-by="key"
                      label="value"
                      :options="status_options"
                    ></multiselect>
                    <div
                      class="error"
                      v-if="submitted && !$v.form.sell.required"
                    >
                      Sell Status is required
                    </div>
                  </div>
                  <!--form-group-->
                </div>
                <!--col-6 sell-->

                <div class="col-xl-3 col-lg-6 col-md-6 col-12 mb-3">
                  <div
                    class="form-group"
                    :class="{ 'form-group--error': $v.form.sell_min.$error }"
                  >
                    <label for="selectbuy" class="form-label">Min Limit</label>

                    <div class="input-group mb-3">
                      <input
                        type="text"
                        class="form-control"
                        id="sellmin"
                        placeholder="Min"
                        @keypress="keyChange($event, decimalLength)"
                        onpaste="return false;"
                        v-model="$v.form.sell_min.$model"
                        aria-describedby="sellmin"
                      />
                      <span class="input-group-text" id="sellmin">$</span>
                    </div>

                    <div
                      class="error"
                      v-if="submitted && !$v.form.sell_min.required"
                    >
                      Min Limit is required
                    </div>
                  </div>
                  <!--form-group-->
                </div>
                <!--col-6 Min-->

                <div class="col-xl-3 col-lg-6 col-md-6 col-12 mb-3">
                  <div
                    class="form-group"
                    :class="{ 'form-group--error': $v.form.sell_max.$error }"
                  >
                    <label for="selectbuy" class="form-label">Max Limit</label>

                    <div class="input-group mb-3">
                      <input
                        type="text"
                        class="form-control"
                        id="sellmax"
                        placeholder="Max"
                        @keypress="keyChange($event, decimalLength)"
                        onpaste="return false;"
                        v-model="$v.form.sell_max.$model"
                        aria-describedby="sellmax"
                      />
                      <span class="input-group-text" id="sellmax">$</span>
                    </div>

                    <div
                      class="error"
                      v-if="submitted && !$v.form.sell_max.required"
                    >
                      Max Limit is required
                    </div>
                  </div>
                </div>
                <!--col-6 Max-->

                <div class="col-xl-6 col-lg-6 col-md-6 col-12 mb-3">
                  <div
                    class="form-group"
                    :class="{
                      'form-group--error': $v.form.sell_commission.$error,
                    }"
                  >
                    <label for="selectbuy" class="form-label"
                      >Sell commission</label
                    >

                    <div class="input-group mb-3">
                      <select
                        class="form-select shadow-none"
                        v-model="form.sell_commission_type"
                      >
                        <option value="percentage">Percentage</option>
                        <option value="flat">Flat</option>
                      </select>
                      <input
                        type="text"
                        class="form-control"
                        id="sellcomm"
                        placeholder="Commission"
                        @keypress="keyChange($event, 2)"
                        onpaste="return false;"
                        v-model="$v.form.sell_commission.$model"
                        aria-describedby="sellcomm"
                      />
                      <span
                        class="input-group-text"
                        id="sellcomm"
                        v-if="form.sell_commission_type == 'percentage'"
                        >%</span
                      >
                      <span class="input-group-text" id="sellcomm" v-else>{{
                        currency
                      }}</span>
                    </div>

                    <div
                      class="error"
                      v-if="submitted && !$v.form.sell_commission.required"
                    >
                      Sell commission is required
                    </div>
                    <div
                      class="error"
                      v-if="
                        form.sell_commission > 99 &&
                        form.sell_commission_type == 'percentage'
                          ? (customError = true)
                          : (customError = false)
                      "
                    >
                      Maximum percentage is 99.
                    </div>
                    <div
                      class="error"
                      v-if="
                        form.sell_min != '' &&
                        parseFloat(form.sell_commission) >
                          parseFloat(form.sell_min) &&
                        form.sell_commission_type == 'flat'
                          ? (customError = true)
                          : (customError = false)
                      "
                    >
                      Sell value should be less than Min limit.
                    </div>
                  </div>
                  <!--form-group-->
                </div>
                <div class="col-xl-3 col-lg-6 col-md-6 col-12 mb-3">
                  <div
                    class="form-group"
                    :class="{
                      'form-group--error': $v.form.sell_min_desc.$error,
                    }"
                  >
                    <label for="selectsell" class="form-label"
                      >Sell Min Description</label
                    >

                    <div class="input-group mb-3">
                      <textarea
                        class="form-control"
                        name="sell_desc"
                        id=""
                        cols="50"
                        rows="3"
                        v-model.trim="$v.form.sell_min_desc.$model"
                      ></textarea>
                    </div>

                    <div
                      class="error"
                      v-if="submitted && !$v.form.sell_min_desc.required"
                    >
                      Sell Min Description is required.
                    </div>
                  </div>
                  <!--form-group-->
                </div>
                <!--col-6 Min-->

                <div class="col-xl-3 col-lg-6 col-md-6 col-12 mb-3">
                  <div
                    class="form-group"
                    :class="{
                      'form-group--error': $v.form.sell_max_desc.$error,
                    }"
                  >
                    <label for="selectsell" class="form-label"
                      >Sell Max Description</label
                    >

                    <div class="input-group mb-3">
                      <textarea
                        class="form-control"
                        name="sell_desc"
                        id=""
                        cols="50"
                        rows="3"
                        v-model.trim="$v.form.sell_max_desc.$model"
                      ></textarea>
                    </div>

                    <div
                      class="error"
                      v-if="submitted && !$v.form.sell_max_desc.required"
                    >
                      Sell Max Description is required
                    </div>
                  </div>
                </div>

                <div class="col-xl-6 col-lg-6 col-md-6 col-12 mb-3">
                  <div
                    class="form-group"
                    :class="{
                      'form-group--error': $v.form.sell_desc.$error,
                    }"
                  >
                    <label for="selectsell" class="form-label"
                      >Sell description</label
                    >

                    <div class="input-group mb-3">
                      <textarea
                        class="form-control"
                        name="sell_desc"
                        id=""
                        cols="100"
                        rows="3"
                        v-model.trim="$v.form.sell_desc.$model"
                      ></textarea>
                    </div>

                    <div
                      class="error"
                      v-if="submitted && !$v.form.sell_desc.required"
                    >
                      Sell description is required
                    </div>
                  </div>
                  <!--form-group-->
                </div>

                <div class="col-12 mt-5">
                  <div class="form-group btn-submit">
                    <div class="spinner-border" role="status" v-if="loading">
                      <span class="visually-hidden">Loading...</span>
                    </div>

                    <button
                      type="submit"
                      class="btn btn-primary"
                      id="submit"
                      v-if="!loading"
                    >
                      Submit
                    </button>
                  </div>
                  <!---submit button-->
                </div>
              </div>
              <!--row form-row-->
            </div>
            <!--sell_box-->

            <!-- <div class="add_coin_form_box mb-3">
              <div class="row bottom-row align-items-center">
                <div class="col-xl-6 col-lg-6 col-md-6 col-12 mb-3">
                  <div
                    class="form-group"
                    :class="{ 'form-group--error': $v.form.deposit.$error }"
                  >
                    <label for="selectbuy" class="form-label">Deposit</label>
                    <multiselect
                      v-model.trim="$v.form.deposit.$model"
                      track-by="key"
                      label="value"
                      :options="status_options"
                    ></multiselect>
                    <div
                      class="error"
                      v-if="submitted && !$v.form.deposit.required"
                    >
                      Deposit Status is required
                    </div>
                  </div>
                 
                </div>
              

                <div class="col-xl-3 col-lg-6 col-md-6 col-12 mb-3">
                  <div
                    class="form-group"
                    :class="{ 'form-group--error': $v.form.deposit_min.$error }"
                  >
                    <label for="selectbuy" class="form-label">Min Limit</label>

                    <div class="input-group mb-3">
                      <input
                        type="text"
                        class="form-control"
                        id="depomin"
                        placeholder="Min"
                        @keypress="keyChange($event, decimalLength)"
                        onpaste="return false;"
                        v-model="$v.form.deposit_min.$model"
                        aria-describedby="depomin"
                      />
                      <span class="input-group-text" id="depomin">$</span>
                    </div>

                    <div
                      class="error"
                      v-if="submitted && !$v.form.deposit_min.required"
                    >
                      Min Limit is required
                    </div>
                  </div>
               
                </div>
         

                <div class="col-xl-3 col-lg-6 col-md-6 col-12 mb-3">
                  <div
                    class="form-group"
                    :class="{ 'form-group--error': $v.form.deposit_max.$error }"
                  >
                    <label for="selectbuy" class="form-label">Max Limit</label>

                    <div class="input-group mb-3">
                      <input
                        type="text"
                        class="form-control"
                        id="depomax"
                        placeholder="Max"
                        @keypress="keyChange($event, decimalLength)"
                        onpaste="return false;"
                        v-model="$v.form.deposit_max.$model"
                        aria-describedby="depomax"
                      />
                      <span class="input-group-text" id="depomax">$</span>
                    </div>

                    <div
                      class="error"
                      v-if="submitted && !$v.form.deposit_max.required"
                    >
                      Max Limit is required
                    </div>
                  </div>
                </div>
               

                <div class="col-xl-6 col-lg-6 col-md-6 col-12 mb-3">
                  <div
                    class="form-group"
                    :class="{
                      'form-group--error': $v.form.deposit_commission.$error,
                    }"
                  >
                    <label for="selectbuy" class="form-label"
                      >Deposit commission</label
                    >

                    <div class="input-group mb-3">
                      <select
                        class="form-select shadow-none"
                        v-model="form.deposit_commission_type"
                      >
                        <option value="percentage">Percentage</option>
                        <option value="flat">Flat</option>
                      </select>

                      <input
                        type="text"
                        class="form-control"
                        id="depocomm"
                        placeholder="Commission"
                        @keypress="keyChange($event, 2)"
                        onpaste="return false;"
                        v-model="$v.form.deposit_commission.$model"
                        aria-describedby="depocomm"
                      />
                      <span
                        class="input-group-text"
                        id="depocomm"
                        v-if="form.deposit_commission_type == 'percentage'"
                        >%</span
                      >
                       <span class="input-group-text" id="depocomm" v-else
                        >$</span
                      >
                    </div>

                    <div
                      class="error"
                      v-if="submitted && !$v.form.deposit_commission.required"
                    >
                      Deposit commission is required.
                    </div>
                    <div
                      class="error"
                      v-if="
                        form.deposit_commission > 99 &&
                        form.deposit_commission_type == 'percentage'
                          ? (customError = true)
                          : (customError = false)
                      "
                    >
                      Maximum percentage is 99.
                    </div>
                    <div
                      class="error"
                      v-if="
                        form.deposit_min != '' &&
                        parseFloat(form.deposit_commission) >
                          parseFloat(form.deposit_min) &&
                        form.deposit_commission_type == 'flat'
                          ? (customError = true)
                          : (customError = false)
                      "
                    >
                      Deposit value should be less than Min limit.
                    </div>
                  </div>
            
                </div>
              </div>
             
            </div> -->
            <!--deposit_box-->

            <!-- <div class="add_coin_form_box mb-3">
              <div class="row bottom-row align-items-center">
                <div class="col-xl-6 col-lg-6 col-md-6 col-12 mb-3">
                  <div
                    class="form-group"
                    :class="{ 'form-group--error': $v.form.withdraw.$error }"
                  >
                    <label for="selectbuy" class="form-label">Withdraw</label>
                    <multiselect
                      v-model.trim="$v.form.withdraw.$model"
                      track-by="key"
                      label="value"
                      :options="status_options"
                    ></multiselect>
                    <div
                      class="error"
                      v-if="submitted && !$v.form.withdraw.required"
                    >
                      Withdraw Status is required
                    </div>
                  </div>
                </div>

                <div class="col-xl-3 col-lg-6 col-md-6 col-12 mb-3">
                  <div
                    class="form-group"
                    :class="{
                      'form-group--error': $v.form.withdraw_min.$error,
                    }"
                  >
                    <label for="selectbuy" class="form-label">Min Limit</label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        class="form-control"
                        id="withmin"
                        placeholder="Min"
                        @keypress="keyChange($event, decimalLength)"
                        onpaste="return false;"
                        v-model="$v.form.withdraw_min.$model"
                        aria-describedby="withmin"
                      />
                      <span class="input-group-text" id="withmin">$</span>
                    </div>

                    <div
                      class="error"
                      v-if="submitted && !$v.form.withdraw_min.required"
                    >
                      Min Limit is required
                    </div>
                  </div>
                </div>

                <div class="col-xl-3 col-lg-6 col-md-6 col-12 mb-3">
                  <div
                    class="form-group"
                    :class="{
                      'form-group--error': $v.form.withdraw_max.$error,
                    }"
                  >
                    <label for="selectbuy" class="form-label">Max Limit</label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        class="form-control"
                        id="withmax"
                        placeholder="Max"
                        @keypress="keyChange($event, decimalLength)"
                        onpaste="return false;"
                        v-model="$v.form.withdraw_max.$model"
                        aria-describedby="withmax"
                      />
                      <span class="input-group-text" id="withmax">$</span>
                    </div>

                    <div
                      class="error"
                      v-if="submitted && !$v.form.withdraw_max.required"
                    >
                      Max Limit is required
                    </div>
                  </div>
                </div>

                <div class="col-xl-6 col-lg-6 col-md-6 col-12 mb-3">
                  <div
                    class="form-group"
                    :class="{
                      'form-group--error': $v.form.withdraw_commission.$error,
                    }"
                  >
                    <label for="selectbuy" class="form-label"
                      >Withdraw Commission</label
                    >

                    <div class="input-group mb-3">
                      <select
                        class="form-select shadow-none"
                        v-model="form.withdraw_commission_type"
                      >
                        <option value="percentage">Percentage</option>
                        <option value="flat">Flat</option>
                      </select>
                      <input
                        type="text"
                        class="form-control"
                        id="withcomm"
                        placeholder="Commission"
                        @keypress="keyChange($event, 2)"
                        onpaste="return false;"
                        v-model="$v.form.withdraw_commission.$model"
                        aria-describedby="withcomm"
                      />
                      <span
                        class="input-group-text"
                        id="withcomm"
                        v-if="form.withdraw_commission_type == 'percentage'"
                        >%</span
                      >
                       <span
                        class="input-group-text"
                        id="withcomm"
                        v-else
                        >{{currency}}</span
                      >
                    </div>

                    <div
                      class="error"
                      v-if="submitted && !$v.form.withdraw_commission.required"
                    >
                      Withdraw commission is required.
                    </div>
                    <div
                      class="error"
                      v-if="
                        form.withdraw_commission > 99 &&
                        form.withdraw_commission_type == 'percentage'
                          ? (customError = true)
                          : (customError = false)
                      "
                    >
                      Maximum percentage is 99.
                    </div>
                    <div
                      class="error"
                      v-if="
                        form.withdraw_min != '' &&
                        parseFloat(form.withdraw_commission) >
                          parseFloat(form.withdraw_min) &&
                        form.withdraw_commission_type == 'flat'
                          ? (customError = true)
                          : (customError = false)
                      "
                    >
                      Withdraw value should be less than Min limit.
                    </div>
                  </div>
                </div>
              </div>
              
            </div> -->
            <!--withdraw_box-->
          </div>
          <!--col-xl-8 col-lg-8 col-md-8-->
        </div>
        <!--row-->
      </form>
    </Layout>
  </div>
</template>

<style scoped>
.add_coin_form_box {
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
  border-radius: 5px;
  padding: 15px 15px;
}

.form-group.btn-submit {
  text-align: center;
}

.form-group .currency-image {
  text-align: center;
  max-width: 44px;
  height: 44px;
  margin: 0 auto;
  border-radius: 26px;
  padding: 0;
  margin-top: 18px;
  margin-left: 0;
}

.form-group .currency-image img {
  max-width: 39px;
  margin-top: 5px;
}
</style>